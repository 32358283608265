import { Severity } from '@sentry/types';
import { EditorSDK, PanelResolveType } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import appFixIllustration from '../assets/images/app-fix-illustration.png';
import pricingPlansIllustration from '../assets/images/pricing-plans.png';
import { toError } from './errors';

const appDefinitionId = pricingPlans.appDefinitionId;

interface CaptureExceptionOptions {
  print: boolean;
  interactionTag: string;
}

export function captureEditorException(
  flowAPI: EditorScriptFlowAPI,
  err: unknown,
  options: Partial<CaptureExceptionOptions> = {},
) {
  if (typeof err === 'object' && err !== null && !(err instanceof Error)) {
    flowAPI.errorMonitor.addBreadcrumb({
      level: Severity.Error,
      message: 'Error object.',
      data: err,
    });
  }
  flowAPI.errorMonitor.captureException(
    toError(err),
    options.interactionTag
      ? {
          tags: {
            interaction: options.interactionTag,
          },
        }
      : {},
  );
  if (options.print) {
    console.error(err);
  }
}

export function suggestNoComponentsFix(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  const t = flowAPI.translations.t;
  return suggestInstallationFix({
    flowAPI,
    sdk,
    panelOptions: {
      illustration: pricingPlansIllustration,
      shouldShowIllustration: true,
      headerText: t('es.install-fix-panel.title'),
      descriptionText: t('es.install-fix-panel.description'),
      mainActionText: t('es.install-fix-panel.main-action'),
      secondaryActionText: t('es.install-fix-panel.secondary-action'),
    },
  });
}

export function suggestNoMainPageFix(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  if (!flowAPI.experiments.enabled(TPA_EXPERIMENTS.SHOW_NO_PAGE_FIX_MODAL)) {
    return;
  }

  const t = flowAPI.translations.t;
  return suggestInstallationFix({
    flowAPI,
    sdk,
    panelOptions: {
      illustration: appFixIllustration,
      shouldShowIllustration: true,
      headerText: t('es.no-page-fix-panel.title'),
      descriptionText: t('es.no-page-fix-panel.description'),
      mainActionText: t('es.no-page-fix-panel.main-action'),
    },
  });
}

function suggestInstallationFix(params: {
  flowAPI: EditorScriptFlowAPI;
  sdk: EditorSDK;
  panelOptions: Parameters<EditorSDK['editor']['openConfirmationPanel']>[1];
}) {
  const { flowAPI, sdk, panelOptions } = params;
  if (flowAPI.environment.isADI) {
    flowAPI.errorMonitor.captureMessage('Cannot fix broken ADI site.');
    return;
  }

  sdk.editor
    .openConfirmationPanel('', panelOptions)
    .then(async (result) => {
      flowAPI.errorMonitor.captureMessage(`Fix action selected: ${result}.`);
      if (result === PanelResolveType.MAIN_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('reinstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          await sdk.tpa.add.application('', {
            appDefinitionId,
            shouldNavigate: true,
            showPageAddedPanel: true,
          });
          flowAPI.fedops.interactionEnded('reinstall_fix');
        } catch (e) {
          captureEditorException(flowAPI, e, {
            interactionTag: 'reinstall_fix',
          });
        }
      } else if (result === PanelResolveType.SECONDARY_ACTION) {
        try {
          flowAPI.fedops.interactionStarted('uninstall_fix');
          await sdk.application.uninstall('', { openConfirmation: false });
          flowAPI.fedops.interactionEnded('uninstall_fix');
        } catch (e) {
          captureEditorException(flowAPI, e, {
            interactionTag: 'uninstall_fix',
          });
        }
      }
    })
    .catch((e) => {
      captureEditorException(flowAPI, e, {
        interactionTag: 'suggest_installation_fix',
      });
    });
}
